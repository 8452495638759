import React, { useState, useEffect } from "react";
import {
	GET_PROFILE,
	PUT_PROFILE,
	PUT_PHOTO_PROFILE,
} from "../../services/user.service";
import { Spacer } from "../../components";
import { Layout, Navbar } from "../../containers";
import {
	Grid,
	Card,
	Button,
	Avatar,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormLabel,
	CircularProgress,
} from "@material-ui/core";
import { WrapProfileMobile, WrapMenu } from "./style";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as IconCalendar } from "../../assets/icons_1/Calendar.svg";
import { ReactComponent as IconCall } from "../../assets/icons_1/Call.svg";
import { ReactComponent as IconImage } from "../../assets/icons_1/Image.svg";
import { ReactComponent as IconLocation } from "../../assets/icons_1/Location.svg";
import { ReactComponent as IconMessage } from "../../assets/icons_1/Message.svg";
import { ReactComponent as IconProfile } from "../../assets/icons_1/Profile.svg";
import { ReactComponent as IconPaper } from "../../assets/icons_1/Paper.svg";
import { ReactComponent as IconUsers } from "../../assets/icons_1/Users.svg";
import swal from "sweetalert";
import constanta from "../../configs/constanta";

const ProfilePageMobile = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState({});
	const { control, handleSubmit, reset } = useForm({
		defaultValues: {
			...user,
		},
	});

	const retrieveDataUser = () => {
		setIsLoading(true);
		let user = JSON.parse(localStorage.getItem("USER_NAISHA"));
		GET_PROFILE(user.id)
			.then((res) => {
				let initialUser = {
					name: res.data.name,
					phone: res.data.phone,
					email: res.data.email,
					gender: res.data.gender,
					bio: res.data.bio,
					birthday: res.data.birthday,
					profile_photos: res.data.profile_photos,
					referral_code: res.data.referral_code,
				};
				setUser(initialUser);
				reset(initialUser);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		retrieveDataUser();
	}, []);

	const profilePhoto = user.profile_photos
		? user.profile_photos.medium.url
		: "https://via.placeholder.com/150";

	return (
		<Layout withNavbar={false} {...props}>
			<Navbar type="navback" title="Profile Saya" {...props} />
			<WrapProfileMobile>
				{isLoading ? (
					<div
						style={{ display: "flex", flexDirection: "column" }}
						className="content">
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								height: "100%",
								justifyContent: "center",
							}}>
							<CircularProgress disableShrink />
							<p style={{ marginTop: "14px" }}>Loading</p>
						</div>
					</div>
				) : (
					<>
						<WrapMenu>
							<Button startIcon={<IconImage />}>
								<div className="wrapper">
									<div>Foto Profile</div>
									<div style={{ color: constanta.COLOR.primary }}>
										<Avatar
											alt="photo saya"
											src={profilePhoto}
											style={{ height: "24px", width: "24px" }}
										/>
									</div>
								</div>
							</Button>
							<Button startIcon={<IconProfile />}>
								<div className="wrapper">
									<div>Nama</div>
									<div style={{ color: constanta.COLOR.primary }}>
										{user?.name ?? ""}
									</div>
								</div>
							</Button>
							<Button startIcon={<IconCall />}>
								<div className="wrapper">
									<div>No HP</div>
									<div style={{ color: constanta.COLOR.primary }}>
										{user?.phone ?? ""}
									</div>
								</div>
							</Button>
							<Button startIcon={<IconMessage />}>
								<div className="wrapper">
									<div>Email</div>
									<div style={{ color: constanta.COLOR.primary }}>
										{user?.email ?? ""}
									</div>
								</div>
							</Button>
							<Button startIcon={<IconPaper />}>
								<div className="wrapper">
									<div>Bio</div>
									<div style={{ color: constanta.COLOR.primary }}>
										{user?.bio ?? ""}
									</div>
								</div>
							</Button>
							<Button startIcon={<IconProfile />}>
								<div className="wrapper">
									<div>Jenis Kelamin</div>
									<div style={{ color: constanta.COLOR.primary }}>
										{user?.gender == null
											? "Tidak ingin memberitahu"
											: user?.gender == 0
											? "Laki-Laki"
											: "Perempuan"}
									</div>
								</div>
							</Button>
							<Button startIcon={<IconCalendar />}>
								<div className="wrapper">
									<div>Tanggal Lahir</div>
									<div style={{ color: constanta.COLOR.primary }}>
										{user?.birthday ?? ""}
									</div>
								</div>
							</Button>
							<Button startIcon={<IconUsers />}>
								<div className="wrapper">
									<div>Kode Referral</div>
									<div style={{ color: constanta.COLOR.primary }}>
										{user?.referral_code ?? ""}
									</div>
								</div>
							</Button>
							{/* <Button startIcon={<IconLocation />}
							>
								<div className="wrapper">
									<div>
										Lokasi
									</div>
									<div style={{color: constanta.COLOR.primary}}>
										{user?.village?.name ?? ""}
									</div>
								</div>
							</Button> */}
						</WrapMenu>

						<div style={{ width: "100%" }}>
							<Button
								size="large"
								style={{ width: "100%" }}
								onClick={() => props.history.push("/akun/profile/edit")}
								color="primary"
								variant="contained">
								Edit Profile
							</Button>
						</div>
					</>
				)}
			</WrapProfileMobile>
		</Layout>
	);
};

export default ProfilePageMobile;
